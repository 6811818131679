export default class Testimonials{
    init(){
        jQuery( '.c-testimonials .c-slider-testimonials' ).slick( {
            slidesToShow: 1,
            focusOnSelect: false,
            accessibility: true,
            lazyLoad: 'progressive',
            infinite: false,
            autoplay: true,
            autoplaySpeed: 5000,
            nextArrow: '.c-testimonials .c-testimonials-next',
            prevArrow: '.c-testimonials .c-testimonials-prev',
        })
    }
}