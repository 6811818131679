import $ from 'jquery';

export default class TextSlider {
	init() {

		if ($('.c-slider-slick').length > 0) {

			$('.c-slider-slick-for').slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: false,
				asNavFor: '.c-slider-slick-nav',
				dots: false,
				arrows: false,
				draggable: false,
				useCSS: false,
				useTransform: false,
				adaptiveHeight: true,
				speed: 0,
			});

			$('.c-slider-slick-nav').slick({
				slidesToShow: 4,
				slidesToScroll: 1,
				asNavFor: '.c-slider-slick-for',
				dots: false,
				arrows: false,
				focusOnSelect: true,
				vertical: true,
				adaptiveHeight: true
			});
		}
	}
}